import * as loginReducers from "../../login/framework/reducer"
import { configureStore } from "@reduxjs/toolkit"
import * as paymentsReducers from "../../payments/framework/reducer"
import * as planReducers from "../../plan/framework/reducer"

const store = configureStore({
  reducer: {
    ...planReducers,
    ...loginReducers,
    ...paymentsReducers,
  },
})

export default store
