import { createAction } from "@reduxjs/toolkit"

export const actions = {}

actions.doLogin = createAction("login.do-login")
actions.doLoginRefresh = createAction("login.do-login-refresh")
actions.doLoginFailed = createAction("login.do-login-failed")

actions.doLogout = createAction("login.do-logout")

actions.doRegister = createAction("login.do-register")
actions.doRegisterFailed = createAction("login.do-register-failed")

actions.sendConfirmationEmailSuccess = createAction(
  "login.send-confirmation-email.success"
)
actions.sendConfirmationEmailFailure = createAction(
  "login.send-confirmation-email.failure"
)

actions.doPasswordReset = createAction("login.do-password-reset")
actions.doPasswordResetFailed = createAction("login.do-password-reset-failed")

actions.doGetUser = createAction("login.do-get-user")
