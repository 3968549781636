import React, { useRef } from "react"
import { OverlayToaster } from "@blueprintjs/core"

export const ToasterContext = React.createContext({
    show: () => undefined
})

export const ToasterProvider = ({ children }) => {
    const toaster = useRef(null)
    return (
        <>
            <OverlayToaster ref={ref => (toaster.current = ref)} />
            <ToasterContext.Provider value={toaster.current}>
                {children}
            </ToasterContext.Provider>
        </>
    )
}

export default ToasterProvider