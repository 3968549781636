import Fuse from "fuse.js"
import _ from "lodash"
import { get, identity } from "lodash/fp"
import { createReducer } from "@reduxjs/toolkit"
import {
  acceptOrRejectInvite,
  createOrUpdateAttendees,
  createOrUpdateCalendarEvents,
  createOrUpdateCalendars,
  createOrUpdateExpenses,
  createOrUpdateItems,
  createOrUpdatePlans,
  onAssociateTag,
  onCreateComment,
  onCreateOrUpdateTag,
  onCreateOrUpdateTags,
  onDissociateTag,
  onRemoveCalendarEvents,
  onRemoveComment,
  onRemoveTag,
  removeAttendee,
  removeCalendar,
  removeDebtor,
  removeItems,
  removePlans,
  restoreArchived,
  setPlanExpenseError,
  syncCommits,
  upsertDebtor,
} from "./actions"

export const plans = createReducer(
  {
    byId: {},
    byArchived: { active: [], archived: [] },
  },

  {
    [createOrUpdatePlans.type]: (state, { payload: plans }) => {
      plans.forEach((plan) => {
        state.byId[plan.id] = plan
        state.byArchived[plan.archived] = addToIndex(
          state.byArchived[plan.archived],
          plan
        )
      })
    },

    [restoreArchived.type]: (state, { payload: planId }) => {
      if (planId == null) {
        return state
      }
      state.byId[planId].archived = false
      state.byArchived[false] = addToIndex(
        state.byArchived[false],
        planId,
        _.identity
      )
      state.byArchived[true] = deleteFromIndex(
        state.byArchived[true],
        planId,
        _.identity
      )

      return state
    },

    [removePlans.type]: (state, { payload: planIds }) => {
      if (planIds == null || planIds.length === 0) {
        return state
      }

      planIds.forEach((id) => {
        state.byId[id].archived = true

        state.byArchived[true] = addToIndex(
          state.byArchived[true],
          id,
          _.identity
        )
        state.byArchived[false] = deleteFromIndex(
          state.byArchived[false],
          id,
          _.identity
        )
      })
      return state
    },

    [acceptOrRejectInvite.type]: (state, { payload: planUser }) => {
      const planId = planUser.planId

      if (planUser.isAccepted === false) {
        if (planId == null) {
          return state
        }

        delete state.byId[planId]
      } else {
        return state
      }
    },
  }
)

const addToIndex = (index, item, getId = get("id")) => {
  const updatedIndex = new Set(index || []).add(getId(item))
  return [...updatedIndex]
}

const deleteFromIndex = (index, item, getId = get("id")) => {
  const updatedIndex = new Set(index || [])
  updatedIndex.delete(getId(item))
  return [...updatedIndex]
}

const deleteBySetOfId = (stateById, set) => {
  const state = { ...stateById }
  return _.omit(state, set || [])
}

export const planItems = createReducer(
  {
    byId: {},
    byPlanId: {},
    byTagId: {},

    searchOptions: {
      isCaseSensitive: false,
      includeScore: false,
      threshold: 0.3,
      distance: 100,
      ignoreLocation: true,

      keys: ["title"],
    },

    search: {
      indexByPlanId: {},
    },
  },
  {
    [createOrUpdateItems.type]: (state, { payload: items }) => {
      if (items == null || items.length === 0) {
        return state
      }

      const planId = items[0].planId

      items.forEach((item) => {
        state.byId[item.id] = item
        state.byPlanId[item.planId] = addToIndex(
          state.byPlanId[item.planId],
          item
        )
        //item.comments.forEach()
        item.tags.forEach((tag) => {
          state.byTagId[tag.id] = addToIndex(state.byTagId[tag.id], item)
        })
      })

      state.search.indexByPlanId[planId] = Fuse.createIndex(
        state.searchOptions.keys,
        Object.values(_.pick(state.byId, state.byPlanId[planId]))
      ).toJSON()
    },

    [onAssociateTag.type]: (state, { payload: assoc }) => {
      state.byTagId[assoc.tag.id] = addToIndex(
        state.byTagId[assoc.tag.id],
        assoc.itemId,
        _.identity
      )

      state.byId[assoc.itemId] = assoc.taggedItem
    },

    [onRemoveTag.type]: (state, { payload: tagId }) => {
      const tag = state.byTagId[tagId]
      if (tag == null) {
        return state
      }
      state.byTag[tagId] = deleteFromIndex(
        state.byTag[tagId],
        tagId,
        _.identity
      )
    },

    [onDissociateTag.type]: (state, { payload: assoc }) => {
      const tag = state.byTagId[assoc.tagId]
      if (tag == null) {
        return state
      }
      delete state.byTagId[assoc.tagId]
    },

    [removeItems.type]: (state, { payload: items }) => {
      if (items.ids == null || items.ids.length === 0 || items.plan == null) {
        return state
      }
      const removingItems = items.ids.map((itemId) => state.byId[itemId])
      removingItems.forEach((item) => {
        state.byId[item.id].tags.forEach(
          (tag) =>
            (state.byTagId[tag.id] = deleteFromIndex(
              state.byTagId[tag.id],
              item.id,
              _.identity
            ))
        )
        state.byPlanId[item.planId] = deleteFromIndex(
          state.byPlanId[item.planId],
          item
        )
        delete state.byId[item.id]
      })
      state.search.indexByPlanId[items.plan] = Fuse.createIndex(
        state.searchOptions.keys,
        Object.values(_.pick(state.byId, state.byPlanId[items.plan]))
      ).toJSON()
    },
  }
)

export const planUsers = createReducer(
  {
    byId: {},
    byUserId: {},
    byPlanId: {},
  },
  {
    [createOrUpdateAttendees.type]: (state, { payload: attendees }) => {
      if (attendees == null || attendees.length === 0) {
        return state
      }

      attendees.forEach((planUser) => {
        state.byId[planUser.id] = planUser
        state.byUserId[planUser.user.id] = addToIndex(
          state.byUserId[planUser.user.id],
          planUser
        )
        state.byPlanId[planUser.planId] = addToIndex(
          state.byPlanId[planUser.planId],
          planUser
        )
      })
    },
    [removeAttendee.type]: (state, { payload: planUserId }) => {
      const planUser = state.byId[planUserId]

      if (!planUser) {
        return state
      }

      delete state.byId[planUser.id]
      state.byUserId[planUser.user.id] = deleteFromIndex(
        state.byUserId[planUser.user.id],
        planUser
      )
      state.byPlanId[planUser.planId] = deleteFromIndex(
        state.byPlanId[planUser.planId],
        planUser
      )
    },
    [acceptOrRejectInvite.type]: (state, { payload: planUser }) => {
      if (planUser.isAccepted === false) {
        delete state.byId[planUser.planId]
        state.byUserId[planUser.user.id] = deleteFromIndex(
          state.byUserId[planUser.user.id],
          planUser
        )

        state.byPlanId[planUser.planId] = deleteFromIndex(
          state.byPlanId[planUser.planId],
          planUser
        )
      } else {
        state.byId[planUser.id] = planUser
      }
    },
  }
)

export const planExpenses = createReducer(
  {
    byId: {},
    byPlanId: {},
    error: null,
  },
  {
    [setPlanExpenseError.type]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [createOrUpdateExpenses.type]: (state, { payload: expenses }) => {
      if (expenses == null || expenses.length === 0) {
        return state
      }
      expenses.forEach((expense) => {
        state.byId[expense.id] = expense
        state.byPlanId[expense.planId] = addToIndex(
          state.byPlanId[expense.planId],
          expense
        )
      })
    },
    [upsertDebtor.type]: (state, { payload: debtorToAdd }) => {
      const expenseId = debtorToAdd.expenseId

      if (!state.byId[expenseId]) {
        return state
      }

      const existingDebtorIndex = state.byId[expenseId].debtors.findIndex(
        (debtor) => debtor.userId === debtorToAdd.userId
      )

      if (existingDebtorIndex !== -1) {
        state.byId[expenseId].debtors[existingDebtorIndex] = debtorToAdd
      } else {
        state.byId[expenseId].debtors.push(debtorToAdd)
      }
    },
    [removeDebtor.type]: (state, { payload: debtorToRemove }) => {
      const expenseId = debtorToRemove.expenseId

      if (!state.byId[expenseId]) {
        return state
      }

      state.byId[expenseId].debtors = state.byId[expenseId].debtors.filter(
        (debtor) => debtor.userId !== debtorToRemove.userId
      )
    },
  }
)

export const planTags = createReducer(
  {
    byId: {},
    byPlanId: {},
    byItemId: {},
  },

  {
    [onCreateOrUpdateTag.type]: (state, { payload: tag }) => {
      state.byId[tag.id] = tag
      state.byPlanId[tag.planId] = addToIndex(state.byPlanId[tag.planId], tag)
    },
    [onCreateOrUpdateTags.type]: (state, { payload: tags }) => {
      tags.forEach((tag) => {
        state.byId[tag.id] = tag
        state.byPlanId[tag.planId] = addToIndex(state.byPlanId[tag.planId], tag)
      })
    },
    [onRemoveTag.type]: (state, { payload: tagId }) => {
      const tag = state.byId[tagId]
      if (tag == null) {
        return state
      }
      state.byPlanId[tag.planId] = deleteFromIndex(
        state.byPlanId[tag.planId],
        tag
      )
      delete state.byId[tagId]
    },
    [onAssociateTag.type]: (state, { payload: assoc }) => {
      state.byItemId[assoc.itemId] = addToIndex(
        state.byItemId[assoc.itemId],
        assoc.tag.id,
        identity
      )
    },
    [onDissociateTag.type]: (state, { payload: assoc }) => {
      state.byItemId[assoc.itemId] = deleteFromIndex(
        state.byItemId[assoc.itemId],
        assoc.tagId,
        identity
      )
    },
    [createOrUpdateItems.type]: (state, { payload: items }) => {
      if (items == null || items.length === 0) {
        return state
      }
      items.forEach((item) => {
        if (!item.tags) {
          return
        }
        item.tags.forEach((tag) => {
          state.byId[tag.id] = tag
          state.byPlanId[tag.planId] = addToIndex(
            state.byPlanId[tag.planId],
            tag
          )
          state.byItemId[item.id] = addToIndex(state.byItemId[item.id], tag)
        })
      })
    },
  }
)

export const planCommits = createReducer(
  {
    byId: {},
    byItemId: {},
  },
  {
    [syncCommits.type]: (state, { payload: committedItem }) => {
      if (state.byItemId[committedItem.id] != null) {
        state.byId = deleteBySetOfId(
          state.byId,
          state.byItemId[committedItem.id]
        )
      }
      state.byItemId[committedItem.id] = []

      committedItem.commits.forEach((commit) => {
        state.byId[commit.id] = commit
        state.byItemId[commit.planItemId] = addToIndex(
          state.byItemId[commit.planItemId],
          commit
        )
      })
    },

    [createOrUpdateItems.type]: (state, { payload: items }) => {
      if (items == null || items.length === 0) {
        return state
      }
      items.forEach((item) => {
        if (!item.commits) {
          return
        }

        if (state.byItemId[item.id] != null) {
          state.byId = deleteBySetOfId(state.byId, state.byItemId[item.id])
        }
        state.byItemId[item.id] = []

        item.commits.forEach((com) => {
          state.byId[com.id] = com
          state.byItemId[com.planItemId] = addToIndex(
            state.byItemId[com.planItemId],
            com
          )
        })
      })
    },
  }
)

export const planCalendars = createReducer(
  {
    byId: {},
    byPlanId: {},
    defaultCalendarsForPlans: {},
  },
  {
    [createOrUpdateCalendars.type]: (state, { payload: calendars }) => {
      if (calendars == null || calendars.length === 0) {
        return state
      }
      calendars.forEach((calendar) => {
        state.byId[calendar.id] = calendar
        state.byPlanId[calendar.planId] = addToIndex(
          state.byPlanId[calendar.planId],
          calendar
        )
        if (calendar.isDefault) {
          state.defaultCalendarsForPlans[calendar.planId] = calendar.id
        }
      })
    },
    [removeCalendar.type]: (state, { payload: calendarId }) => {
      if (!state.byId[calendarId]) {
        return state
      }
      const deleting = state.byId[calendarId]

      state.byPlanId[deleting.planId] = deleteFromIndex(
        state.byPlanId[deleting.planId],
        deleting
      )
      delete state.byId[calendarId]
    },
  }
)

const parseDate = (date) => date.toISOString().slice(0, 10)

export const planCalendarEvents = createReducer(
  {
    byId: {},
    byCalendarId: {},
    byDate: {},
    dateByCalendarId: {},
  },
  {
    [createOrUpdateCalendarEvents.type]: (state, { payload: events }) => {
      if (events == null || events.length === 0) {
        return state
      }
      events.forEach((event) => {
        const start = parseDate(event.startTime)
        const end = parseDate(event.endTime)
        state.byId[event.id] = event
        state.byCalendarId[event.calendar] = addToIndex(
          state.byCalendarId[event.calendar],
          event
        )
        state.dateByCalendarId[event.calendar] = addToIndex(
          state.dateByCalendarId[event.calendar],
          start,
          _.identity
        )
        state.byDate[start] = addToIndex(state.byDate[start], event)
        if (start !== end) {
          for (
            let newDate = new Date(event.startTime);
            newDate <= event.endTime;
            newDate.setDate(newDate.getDate() + 1)
          ) {
            state.dateByCalendarId[event.calendar] = addToIndex(
              state.dateByCalendarId[event.calendar],
              parseDate(newDate),
              _.identity
            )
            state.byDate[parseDate(newDate)] = addToIndex(
              state.byDate[parseDate(newDate)],
              event
            )
          }
        }
      })
    },
    [onRemoveCalendarEvents.type]: (state, { payload: events }) => {
      if (events == null || events.length === 0) {
        return state
      }
      events.forEach((event) => {
        const ref = state.byId[event]
        const start = parseDate(ref.startTime)
        const end = parseDate(ref.endTime)

        state.byCalendarId[ref.calendar] = deleteFromIndex(
          state.byCalendarId[ref.calendar],
          state.byId[event]
        )

        if (start !== end) {
          for (
            let dateIterator = new Date(ref.startTime);
            dateIterator <= ref.endTime;
            dateIterator.setDate(dateIterator.getDate() + 1)
          ) {
            state.byDate[parseDate(dateIterator)] = deleteFromIndex(
              state.byDate[parseDate(dateIterator)],
              ref
            )

            if (state.byDate[parseDate(dateIterator)].length === 0) {
              delete state.byDate[parseDate(dateIterator)]
              state.dateByCalendarId[ref.calendar] = deleteFromIndex(
                state.dateByCalendarId[ref.calendar],
                parseDate(dateIterator),
                _.identity
              )
            }
          }
        }

        delete state.byId[event]
      })
    },
  }
)

export const planComments = createReducer(
  {
    byId: {},
    byItemId: {},
  },
  {
    [createOrUpdateItems.type]: (state, { payload: items }) => {
      if (items == null || items.length === 0) {
        return state
      }
      items.forEach((item) => {
        if (!item.comments) {
          return
        }
        item.comments.forEach((com) => {
          state.byId[com.id] = com
          state.byItemId[com.planItemId] = addToIndex(
            state.byItemId[com.planItemId],
            com
          )
        })
      })
    },
    [onCreateComment.type]: (state, { payload: comment }) => {
      state.byId[comment.id] = comment
      state.byItemId[comment.planItemId] = addToIndex(
        state.byItemId[comment.planItemId],
        comment
      )
    },
    [onRemoveComment.type]: (state, { payload: commentId }) => {
      const comment = state.byId[commentId]
      if (comment == null) {
        return state
      }

      state.byItemId[comment.planItemId] = deleteFromIndex(
        state.byItemId[comment.planItemId],
        comment
      )
      delete state.byId[commentId]
    },
  }
)
