import { createReducer } from "@reduxjs/toolkit"
import { hydrate } from "../../common/redux/actions"
import { AccessTokens } from "../domain/access-tokens"
import { User } from "../domain/user"
import { actions } from "./actions"

const getPayloadError = (action) => action.payload.error

const setError =
  (key, actionGetter = getPayloadError) =>
  (state, action) => ({
    ...state,
    [key]: actionGetter(action),
  })

export const login = createReducer(
  {
    hydrated: false,
    user: new User({}),
    registeredUser: null,
    registerError: null,
    accessTokens: new AccessTokens({}),
    error: null,
    successfulReset: null,
  },
  {
    [hydrate.type]: (state, action) => {
      return state.accessTokens.access
        ? state
        : {
          ...state,
            hydrated: true,
            accessTokens: new AccessTokens({
              access: localStorage.getItem("accessToken"),
              refresh: localStorage.getItem("refreshToken"),
            }),
          }
    },
    [actions.doLogin.type]: (state, action) => {
      const accessTokens = action.payload
      localStorage.setItem("accessToken", accessTokens.access)
      localStorage.setItem("refreshToken", accessTokens.refresh)
      return { ...state, accessTokens, error: null }
    },
    [actions.doRegister.type]: (state, action) => {
      const { user } = action.payload
      return { ...state, registeredUser: user, error: null }
    },
    [actions.doLoginRefresh.type]: (state, action) => {
      const user = action.payload
      localStorage.setItem("accessToken", user.access)
      return {
        ...state,
        accessTokens: { ...state.accessTokens, access: user.access },
      }
    },
    [actions.doLoginFailed.type]: setError(`error`),
    [actions.doRegisterFailed.type]: setError(`registerError`),
    [actions.doLogout.type]: (state) => {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      return {
        ...state,
        error: "",
        user: new User({}),
        accessTokens: new AccessTokens({}),
      }
    },
    [actions.sendConfirmationEmailFailure.type]: setError(`error`),
    [actions.doGetUser.type]: (state, action) => {
      const user = action.payload.user
      return { ...state, error: "", user: user }
    },
    [actions.doPasswordResetFailed.type]: setError(`error`),
    [actions.doPasswordReset.type]: (state, action) => ({
      ...state,
      error: "",
      successfulReset: action.payload.status
    }),
  }
)
