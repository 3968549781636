export class User {
  constructor({
    id,
    email,
    full_name,
    first_name,
    last_name,
    default_payout,
    default_billing,
    is_active,
  }) {
    this.email = email
    this.id = id
    this.email = email
    this.fullName = full_name
    this.firstName = first_name
    this.lastName = last_name
    this.defaultPayout = default_payout
    this.defaultBilling = default_billing
    this.isActive = is_active
  }

  toJSON() {
    return {
      id: this.id,
      email: this.email,
      full_name: this.fullName,
      first_name: this.firstName,
      last_name: this.lastName,
      default_payout: this.defaultPayout,
      default_billing: this.defaultBilling,
      is_active: this.isActive,
    }
  }

  static fromJSON(json) {
    return new User(json)
  }
}
