import { createAction } from "@reduxjs/toolkit"

export const createOrUpdatePlans = createAction("plan.bulk.create-or-update")
export const restoreArchived = createAction("plan.bulk.restore-archived")
export const createOrUpdatePlan = (plan) => createOrUpdatePlans([plan])
export const removePlans = createAction("plan.bulk.remove")
export const removePlan = (planId) => removePlans([planId])

export const createOrUpdateItems = createAction(
  "plan-item.bulk.create-or-update"
)
export const createOrUpdateItem = (planItem) => createOrUpdateItems([planItem])

export const removeItems = createAction("plan-item.bulk.remove")
export const removeItem = (id, planId) =>
  removeItems({ plan: planId, ids: [id] })

export const createOrUpdateAttendees = createAction(
  "plan-user.bulk.create-or-update"
)
export const createOrUpdateAttendee = (planUser) =>
  createOrUpdateAttendees(planUser)
export const removeAttendee = createAction("plan-user.bulk.remove")

export const createOrUpdateExpenses = createAction(
  "plan-expense.bulk.create-or-update"
)
export const createOrUpdateExpense = (planExpense) =>
  createOrUpdateExpenses([planExpense])
export const setPlanExpenseError = createAction("plan-expense.error")

export const onCreateOrUpdateTag = createAction(`plan.tags.create-or-update`)
export const onCreateOrUpdateTags = createAction(
  `plan.tags.bulk.create-or-update`
)
export const onRemoveTag = createAction(`plan.tags.remove`)
export const onAssociateTag = createAction(`plan.tags.association.create`)
export const onDissociateTag = createAction(`plan.tags.association.remove`)

export const onResendInvitation = createAction(`plan-user-invitation.send`)

export const syncCommits = createAction("plan.commits.add")

export const upsertDebtor = createAction("plan-expense.debtor.create-or-update")
export const removeDebtor = createAction("plan-expense.debtor.remove")
export const acceptOrRejectInvite = createAction(`plan-user.accept-or-reject`)

export const createOrUpdateCalendars = createAction(
  "plan.calendars.create-or-update"
)
export const removeCalendar = createAction("plan.calendars.remove")
export const createOrUpdateCalendarEvents = createAction(
  "plan.calendars.events.create-or-update"
)
export const createOrUpdateCalendarEvent = (event) =>
  createOrUpdateCalendarEvents([event])

export const onRemoveCalendarEvents = createAction("plan.calendars.bulk.remove")
export const onRemoveCalendarEvent = (eventId) =>
  onRemoveCalendarEvents([eventId])

export const onCreateComment = createAction("plan.comment.create")
export const onRemoveComment = createAction("plan.comment.remove")
