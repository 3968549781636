const replaceInvalidCharacters = (str) => str.replace(/\//, "-")

export const config = {
  appName: "Gohort",
  appNamespace: "gohort",
  titleTemplate: "%s | Gohort",
  defaultTitle: "Gohort",
  mapBoxToken: process.env.NEXT_PUBLIC_MAP_BOX_TOKEN,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_HOST ?? "/api/v1/",
  stripePublicKey: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  stripeIntentClientId: process.env.NEXT_PUBLIC_STRIPE_INTENT_CLIENT_ID,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE ?? 0.2,
  },
  appSupportLink:
    process.env.NEXT_PUBLIC_SUPPORT_LINK ??
    "https://www.notion.so/joshpaulchan/Support-aed977455e2348a7815564c6e36f3d3c",
  environment: process.env.NEXT_PUBLIC_ENV ?? "unknown",
  version: [
    process.env.npm_package_version,
    process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    process.env.NEXT_PUBLIC_BRANCH,
  ]
    .filter(Boolean)
    .map(replaceInvalidCharacters)
    .join("-"),
  featureFlags: {
    commentsEnabled:
      process.env.NEXT_PUBLIC_FEATURE_FLAGS_COMMENTS_ENABLED ?? false,
  },
}

export default config
