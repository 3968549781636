import { createReducer } from "@reduxjs/toolkit"
import { actions } from "./actions"

export const payments = createReducer(
  {
    intentClientSecret: null,
    billingMethods: [],
    payoutMethods: [],
    currencies: [
      {
        value: "USD",
        label: "USD (US Dollar)",
      },
    ],
  },
  {
    [actions.doCreateIntent.type]: (state, action) => {
      const { intent } = action.payload
      return { ...state, intentClientSecret: intent.client_secret }
    },
    [actions.doBillingLoaded.type]: (state, action) => {
      return { ...state, billingMethods: action.payload.billingMethods }
    },
    [actions.doBillingMethodAdded.type]: (state, action) => {
      return {
        ...state,
        billingMethods: [...state.billingMethods, action.payload.billingMethod],
      }
    },
    [actions.doPayoutMethodsLoaded.type]: (state, action) => {
      return { ...state, payoutMethods: action.payload.payoutMethods }
    },
    [actions.doPayoutMethodAdded.type]: (state, action) => {
      return {
        ...state,
        payoutMethods: [...state.payoutMethods, action.payload.payoutMethod],
      }
    },
    [actions.doSetCurrencies.type]: (state, action) => {
      return { ...state, currencies: action.payload.currencies }
    },
  }
)
