export class AccessTokens {
  constructor({ refresh, access }) {
    this.access = access
    this.refresh = refresh
  }

  toJSON() {
    return {
      access: this.access,
      refresh: this.refresh,
    }
  }

  static fromJSON(json) {
    return new AccessTokens(json)
  }
}
