import { createAction } from "@reduxjs/toolkit"

export const actions = {}

actions.doCreateIntent = createAction("payments.stripe.create-intent")

actions.doBillingLoaded = createAction("payments.billing-loaded")
actions.doBillingMethodAdded = createAction("payments.billing-method-added")

actions.doPayoutMethodsLoaded = createAction("payments.payout-methods-loaded")
actions.doPayoutMethodAdded = createAction("payments.payout-method-added")

actions.doSetCurrencies = createAction("payments.set_currencies")
