import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import "@blueprintjs/core/lib/css/blueprint.css"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css"
import "mapbox-gl/dist/mapbox-gl.css"
import "../index.css"
import "../App.css"
import "../common/ui/container.css"
import "../plan/ui/avatar.css"
import "../plan/ui/dashboard-join.css"
import "../plan/ui/plans-panel.css"
import "../plan/ui/plans.css"
import "../plan/ui/calendars/calendars.css"
import "../plan/ui/expenses/expense-form.css"
import "../plan/ui/items/plan-items-dashboard.css"
import "../plan/ui/items/plan-items.css"
import "../plan/ui/items/comments/comments.css"
import Head from "next/head"

import React from "react"
import { Provider } from "react-redux"
import { useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import store from "../common/redux/store"
import { config } from "../config"
import { hydrate } from "../common/redux/actions"
import {ToasterProvider} from "../common/ui/toaster"
import Script from "next/script";

const App = ({ Component, pageProps }) => {
  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    store.dispatch(hydrate())
  }, [])

  if (Component.waitForMount && !isMounted) {
    return null
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Head>
        <title>{config.defaultTitle}</title>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
      !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
      analytics.load("bh1YtrAUBsE9jAjsbneLMdL7weAYONNJ");
      analytics.page();
      }}();
    `,
          }}
        />
        <Script src="https://scripts.simpleanalyticscdn.com/latest.js"  />
      </Head>
      <noscript>
        {/* eslint-disable @next/next/no-img-element */}
        <img
          src="https://queue.simpleanalyticscdn.com/noscript.gif"
          alt=""
          referrerPolicy="no-referrer-when-downgrade"
        />
        You need to enable JavaScript to run this app.
      </noscript>
      <Provider store={store}>
        <ToasterProvider>
          <Component {...pageProps} />
        </ToasterProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

export default App
