// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { config } from "./src/config"

Sentry.init({
  dsn: config.sentry.dsn,
  tracesSampleRate: config.sentry.tracesSampleRate,
  environment: config.environment,
  release: `neo-web@v${config.version}`,
})